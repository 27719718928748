<template>
  <a-card class="w-100">
    <h6>
      Dados do seu carro
    </h6>
    <a-card class="bg-success text-center" hoverable>
      <h5 class="text-white text-uppercase">
        {{ this.formAuto.bem.marca }}
      </h5>
      <h6 class="text-white text-uppercase">
        {{ this.formAuto.bem.veiculo }}
      </h6>
      <h6 class="text-white text-uppercase">
        {{ this.formAuto.bem.ano_modelo }} |
        {{ this.formAuto.bem.combustivel }}
      </h6>
    </a-card>
    <h6 class="mt-4">
      Os seus dados estão corretos?
    </h6>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Como podemos chamar você</small><br />
        {{ formAuto.nomeCliente }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Como prefere ser atendido(a)</small><br />
        {{
          formAuto.tipoAtendimento === "W"
            ? "Whatsapp(recomendado)"
            : "Telefone, prefiro que me liguem"
        }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Contato</small><br />
        {{ formAuto.celular }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Placa</small><br />
        <span class="text-uppercase">
          {{ formAuto.placa }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Chassi:</small><br />
        <span class="text-uppercase">
          {{ formAuto.chassi }}
        </span>
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Qual a utilização do veículo?</small><br />
        {{ formAuto.tipoUtilizacao === "P" ? "Particular" : "Comercial" }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Já possui seguro</small><br />
        {{ formAuto.possuiSeguro === 1 ? "Sim" : "Não" }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3" v-if="formAuto.possuiSeguro === 1">
        <small class="text-muted">Qual a seguradora</small><br />
        {{ formAuto.idSeguradora | filterSeguradoras(stateSeguradoras) }}
      </div>
    </div>
    <div class="row" v-if="formAuto.possuiSeguro === 1">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Qual a classe de bônus</small><br />
        {{ formAuto.classeBonus }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Houve sinistro na última vigência</small
        ><br />
        {{ formAuto.houveSinistro === 1 ? "Sim" : "Não" }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted"
          >Cobertura para condutor(a) entre 18 a 25 anos</small
        ><br />
        {{ formAuto.cobertura1825 === 1 ? "Sim" : "Não" }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <div v-if="formAuto.cpf !== ''">
          <small class="text-muted">CPF:</small><br />
          {{ formAuto.cpf }}
        </div>
        <div v-if="formAuto.cnpj !== ''">
          <small class="text-muted">CNPJ:</small><br />
          {{ formAuto.cnpj }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Data de nascimento</small><br />
        {{ formAuto.nascimento }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">CEP</small><br />
        {{ formAuto.cep }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Estado civil</small><br />
        {{ formAuto.idEstadoCivil | filterEstadosCivis(stateEstadosCivis) }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Quantos anos de habilitação possui</small
        ><br />
        {{
          formAuto.anosHabilitacao > 1
            ? `${formAuto.anosHabilitacao} anos`
            : `${formAuto.anosHabilitacao} ano`
        }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Qual o seu e-mail</small><br />
        <span class="text-lowercase">
          {{ formAuto.email }}
        </span>
      </div>
    </div>
  </a-card>
</template>

<script>
import "@/filters/generic-filters/filter-arrays";
import { mapGetters } from "vuex";
export default {
  props: ["formAuto"],
  computed: {
    ...mapGetters([
      "stateSeguradoras",
      "stateEstadosCivis",
      "stateMarcasCarros",
    ]),
  },
};
</script>
