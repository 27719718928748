<template>
  <div class="bg-white">
    <section class="bg-white pb-5">
      <div class="row justify-content-center m-3">
        <div class="col-sm-8 col-md-5 col-lg-4">
          <a-progress
            :percent="percent"
            strokeColor="#28a745"
            :strokeWidth="2"
            :showInfo="false"
          />
          <div v-if="etapa === 0">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">Como podemos chamar você?</h4>
            </div>
            <div class="row">
              <div class="col">
                <a-input
                  ref="nomeCliente"
                  placeholder="Escreva o seu nome aqui"
                  size="large"
                  type="text"
                  v-model.trim="formAuto.nomeCliente"
                />
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.nomeCliente === ''"
                :classess="
                  formAuto.nomeCliente === ''
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 1">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">
                Olá {{ formAuto.nomeCliente }}, como prefere ser atendido(a)?
              </h4>
            </div>
            <div class="row">
              <div class="col">
                <a-radio-group
                  v-model="formAuto.tipoAtendimento"
                  class="w-100"
                  @change="habilitarFocoNoInput()"
                >
                  <a-radio
                    value="W"
                    class="p-4 border w-100 mb-2"
                    :class="
                      formAuto.tipoAtendimento === 'W'
                        ? 'bg-success text-white'
                        : ''
                    "
                  >
                    Whatsapp(recomendado)
                  </a-radio>
                  <a-radio
                    value="T"
                    class="p-4 border w-100 mb-2"
                    :class="
                      formAuto.tipoAtendimento === 'T'
                        ? 'bg-success text-white'
                        : ''
                    "
                  >
                    Telefone, prefiro que me liguem
                  </a-radio>
                </a-radio-group>
                <div
                  class="form-group mt-3"
                  v-show="formAuto.tipoAtendimento !== ''"
                >
                  <label>{{
                    formAuto.tipoAtendimento === "W"
                      ? "Whatsapp(recomendado)"
                      : "Telefone"
                  }}</label>
                  <a-input
                    ref="celular"
                    :placeholder="
                      formAuto.tipoAtendimento === 'W'
                        ? 'Informe o seu Whatsapp'
                        : 'Informe um telefone'
                    "
                    size="large"
                    type="text"
                    :maxLength="14"
                    v-model.trim="formAuto.celular"
                    v-mask="'(##)#####-####'"
                  />
                </div>
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="
                  formAuto.tipoAtendimento === '' || formAuto.celular === ''
                "
                :classess="
                  formAuto.tipoAtendimento === '' || formAuto.celular === ''
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="cadastrarCliente()"
              />
            </div>
          </div>
          <div v-if="etapa === 2">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">Qual a marca do veículo?</h4>
            </div>
            <div class="row">
              <div class="col">
                <a-select
                  show-search
                  class="w-100"
                  size="large"
                  :filterOption="filterOption"
                  v-model="formAuto.idMarca"
                  @change="listarMarcasCarrosModelos($event)"
                >
                  <a-select-option
                    :value="veiculo.id"
                    v-for="(veiculo, key) in stateMarcasCarros"
                    :key="key"
                  >
                    {{ veiculo.fipe_name }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.idMarca === null"
                :classess="
                  formAuto.idMarca === null
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 3">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">Qual o modelo do veículo?</h4>
            </div>
            <div class="row">
              <div class="col">
                <a-select
                  show-search
                  class="w-100"
                  size="large"
                  :filterOption="filterOption"
                  v-model="formAuto.idModelo"
                  @change="listarMarcasCarrosModelosAno($event)"
                >
                  <a-select-option
                    :value="modelo.id"
                    v-for="(modelo, key) in stateMarcasCarrosModelos"
                    :key="key"
                  >
                    {{ modelo.fipe_name }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.idModelo === null"
                :classess="
                  formAuto.idModelo === null
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 4">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">Qual o ano modelo?</h4>
            </div>
            <div class="row">
              <div class="col">
                <a-select
                  show-search
                  class="w-100"
                  size="large"
                  :filterOption="filterOption"
                  v-model="formAuto.anoModelo"
                  @change="listarMarcasCarrosModelosAnoDetalhe($event)"
                >
                  <a-select-option
                    :value="ano.id"
                    v-for="(ano, key) in stateMarcasCarrosModelosAno"
                    :key="key"
                  >
                    {{ ano.fipe_codigo.substring(0, 4) }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.anoModelo === null"
                :classess="
                  formAuto.anoModelo === null
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 5">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">Este é o seu carro?</h4>
            </div>
            <div class="row">
              <div class="col">
                <a-card class="bg-success text-center" hoverable>
                  <h5 class="text-white text-uppercase">
                    {{ stateMarcasCarrosModelosAnoDetalhe.marca }}
                  </h5>
                  <h6 class="text-white text-uppercase">
                    {{ stateMarcasCarrosModelosAnoDetalhe.veiculo }}
                  </h6>
                  <h6 class="text-white text-uppercase">
                    {{ stateMarcasCarrosModelosAnoDetalhe.ano_modelo }} |
                    {{ stateMarcasCarrosModelosAnoDetalhe.combustivel }}
                  </h6>
                </a-card>
              </div>
            </div>
            <div class="text-center mt-5">
              <h6
                class="mb-4 text-success cursor-pointer"
                @click="irParaEtapa(2)"
              >
                NÃO, QUERO ALTERAR!
              </h6>
              <Button
                label="SIM, CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="false"
                :classess="'bg-success text-white shadow'"
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 6">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">Placa?</h4>
            </div>
            <div class="row">
              <div class="col">
                <a-input
                  ref="placa"
                  placeholder="Informe a placa"
                  size="large"
                  type="text"
                  :maxLength="8"
                  v-model.trim="formAuto.placa"
                  v-mask="'AAA-####'"
                />
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.placa === ''"
                :classess="
                  formAuto.placa === ''
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 7">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">Chassi?</h4>
            </div>
            <div class="row">
              <div class="col">
                <a-input
                  ref="chassi"
                  placeholder="Informe o número do chassi"
                  size="large"
                  type="text"
                  v-model.trim="formAuto.chassi"
                />
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.chassi === ''"
                :classess="
                  formAuto.chassi === ''
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 8">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">Qual a utilização do veículo?</h4>
            </div>
            <div class="row">
              <div class="col">
                <a-radio-group v-model="formAuto.tipoUtilizacao" class="w-100">
                  <a-radio
                    value="P"
                    class="p-4 border w-100 mb-2"
                    :class="
                      formAuto.tipoUtilizacao === 'P'
                        ? 'bg-success text-white'
                        : ''
                    "
                  >
                    Particular
                  </a-radio>
                  <a-radio
                    value="C"
                    class="p-4 border w-100 mb-2"
                    :class="
                      formAuto.tipoUtilizacao === 'C'
                        ? 'bg-success text-white'
                        : ''
                    "
                  >
                    Comercial
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.tipoUtilizacao === null"
                :classess="
                  formAuto.tipoUtilizacao === null
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 9">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">
                Já possui seguro
              </h4>
            </div>
            <div class="row">
              <div class="col">
                <a-radio-group v-model="formAuto.possuiSeguro" class="w-100">
                  <a-radio
                    :value="1"
                    class="p-4 border w-100 mb-2"
                    :class="
                      formAuto.possuiSeguro === 1 ? 'bg-success text-white' : ''
                    "
                  >
                    Sim
                  </a-radio>
                  <a-radio
                    :value="0"
                    class="p-4 border w-100 mb-2"
                    :class="
                      formAuto.possuiSeguro === 0 ? 'bg-success text-white' : ''
                    "
                  >
                    Não
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.possuiSeguro === ''"
                :classess="
                  formAuto.possuiSeguro === ''
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="
                  formAuto.possuiSeguro === 1 ? next() : naoPossuiSeguro(13)
                "
              />
            </div>
          </div>
          <div v-if="etapa === 10">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">Qual a seguradora?</h4>
            </div>
            <div class="row">
              <div class="col">
                <a-select
                  show-search
                  class="w-100"
                  size="large"
                  :filterOption="filterOption"
                  v-model="formAuto.idSeguradora"
                >
                  <a-select-option
                    :value="seguradora.id"
                    v-for="(seguradora, key) in stateSeguradoras"
                    :key="key"
                  >
                    {{ seguradora.seguradora_nome }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.idSeguradora === null"
                :classess="
                  formAuto.idSeguradora === null
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 11">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">Qual a classe de bônus?</h4>
              <h6
                class="text-success cursor-pointer"
                @click="infoClasseBonus()"
              >
                MAS O QUE É CLASSE DE BÔNUS??🤔
              </h6>
              <a-modal
                :visible="modalClasseBonus"
                :footer="null"
                @cancel="okClasseBonus()"
              >
                <div>
                  <h5 class="mb-3">
                    As classes de bônus são descontos progressivos concedidos
                    pelas seguradoras aos segurados que não tiveram sinistros
                    durante a vigência de seu seguro.
                  </h5>
                  <h5 class="mb-3">
                    Elas são concedidas na hora da renovação da apólice, isto é,
                    após completado um ano de seguro. Caso o segurado não tenha
                    precisado acionar o seguro durante a vigência da apólice,
                    ele receberá uma classe de bônus na renovação
                  </h5>
                  <h5 class="mb-3">
                    O processo se repetirá a cada renovação sem sinistros. Cada
                    classe corresponde a um percentual de desconto sobre o
                    prêmio do seguro no próximo ano, geralmente um percentual de
                    5%.
                  </h5>
                  <h5 class="mb-3">
                    Vale lembrar que as classes de bônus são atreladas ao
                    segurado, não ao veículo ou à seguradora. Assim, elas são
                    mantidas mesmo se o segurado trocar de carro ou mudar de
                    seguradora ou corretora de seguros.
                  </h5>
                </div>
                <div class="text-right">
                  <Button
                    label="OK, ENTENDI! 😎"
                    :loading="false"
                    :size="'large'"
                    :classess="'bg-success text-white shadow'"
                    @emitter-click="okClasseBonus()"
                  />
                </div>
              </a-modal>
            </div>
            <div class="row">
              <div class="col">
                <a-select
                  show-search
                  class="w-100"
                  size="large"
                  :filterOption="filterOption"
                  v-model="formAuto.classeBonus"
                >
                  <a-select-option :value="0">
                    0
                  </a-select-option>
                  <a-select-option :value="1">
                    1
                  </a-select-option>
                  <a-select-option :value="2">
                    2
                  </a-select-option>
                  <a-select-option :value="3">
                    3
                  </a-select-option>
                  <a-select-option :value="4">
                    4
                  </a-select-option>
                  <a-select-option :value="5">
                    5
                  </a-select-option>
                  <a-select-option :value="6">
                    6
                  </a-select-option>
                  <a-select-option :value="7">
                    7
                  </a-select-option>
                  <a-select-option :value="8">
                    8
                  </a-select-option>
                  <a-select-option :value="9">
                    9
                  </a-select-option>
                  <a-select-option :value="10">
                    10
                  </a-select-option>
                </a-select>
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.classeBonus === null"
                :classess="
                  formAuto.classeBonus === null
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 12">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">Houve sinistro na última vigência?</h4>
            </div>
            <div class="row">
              <div class="col">
                <a-radio-group v-model="formAuto.houveSinistro" class="w-100">
                  <a-radio
                    :value="1"
                    class="p-4 border w-100 mb-2"
                    :class="
                      formAuto.houveSinistro === 1
                        ? 'bg-success text-white'
                        : ''
                    "
                  >
                    Sim
                  </a-radio>
                  <a-radio
                    :value="0"
                    class="p-4 border w-100 mb-2"
                    :class="
                      formAuto.houveSinistro === 0
                        ? 'bg-success text-white'
                        : ''
                    "
                  >
                    Não
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.houveSinistro === null"
                :classess="
                  formAuto.houveSinistro === null
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 13">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">
                Deseja cobertura para condutor(a) entre 18 a 25 anos?
              </h4>
            </div>
            <div class="row">
              <div class="col">
                <a-radio-group v-model="formAuto.cobertura1825" class="w-100">
                  <a-radio
                    :value="1"
                    class="p-4 border w-100 mb-2"
                    :class="
                      formAuto.cobertura1825 === 1
                        ? 'bg-success text-white'
                        : ''
                    "
                  >
                    Sim
                  </a-radio>
                  <a-radio
                    :value="0"
                    class="p-4 border w-100 mb-2"
                    :class="
                      formAuto.cobertura1825 === 0
                        ? 'bg-success text-white'
                        : ''
                    "
                  >
                    Não
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.cobertura1825 === null"
                :classess="
                  formAuto.cobertura1825 === null
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 14">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">Informe o seu CPF ou CNPJ</h4>
            </div>
            <div class="row">
              <div class="col">
                <a-input
                  ref="cpf"
                  placeholder="Qual o seu CPF"
                  size="large"
                  type="text"
                  v-mask="'###.###.###-##'"
                  :maxLength="14"
                  v-model.trim="formAuto.cpf"
                  @change="limparCampo('cnpj'), testarCpfValido($event)"
                />
                <span v-if="this.exibeMsgCpf" class="text-danger ml-1"
                  >CPF inválido</span
                >
                <a-input
                  class="mt-3"
                  ref="cnpj"
                  placeholder="Qual o seu CNPJ"
                  size="large"
                  type="text"
                  v-mask="'##.###.###/####-##'"
                  :maxLength="18"
                  v-model.trim="formAuto.cnpj"
                  @change="limparCampo('cpf')"
                />
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.cpf === '' && formAuto.cnpj === ''"
                :classess="
                  formAuto.cpf === '' && formAuto.cnpj === ''
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 15">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">
                Data de nascimento?
              </h4>
            </div>
            <div class="row">
              <div class="col">
                <the-mask
                  ref="nascimento"
                  placeholder="Qual sua data de nascimento"
                  :maxLength="10"
                  :mask="['##/##/####']"
                  :masked="true"
                  v-model.trim="formAuto.nascimento"
                  class="form-control"
                />
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.nascimento === ''"
                :classess="
                  formAuto.nascimento === ''
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 16">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">CEP?</h4>
            </div>
            <div class="row">
              <div class="col">
                <a-input
                  ref="cep"
                  placeholder="Qual o seu CEP"
                  size="large"
                  type="text"
                  :maxLength="10"
                  v-mask="'##.###-###'"
                  v-model.trim="formAuto.cep"
                />
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.cep === ''"
                :classess="
                  formAuto.cep === ''
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 17">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">Estado civil?</h4>
            </div>
            <div class="row">
              <div class="col">
                <a-radio-group v-model="formAuto.idEstadoCivil" class="w-100">
                  <a-radio
                    :value="estadoCivil.id"
                    class="p-4 border w-100 mb-2"
                    v-for="(estadoCivil, key) in stateEstadosCivis"
                    :key="key"
                    :class="
                      formAuto.idEstadoCivil === estadoCivil.id
                        ? 'bg-success text-white'
                        : ''
                    "
                  >
                    {{ estadoCivil.nome }}
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.estadoCivil === ''"
                :classess="
                  formAuto.estadoCivil === ''
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 18">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">
                Quantos anos de habilitação possui?
              </h4>
            </div>
            <div class="row">
              <div class="col">
                <a-input
                  ref="anosHabilitacao"
                  placeholder="Quantos anos de habilitação você tem?"
                  size="large"
                  type="number"
                  v-model.trim="formAuto.anosHabilitacao"
                />
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.anosHabilitacao === null"
                :classess="
                  formAuto.anosHabilitacao === null
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
          </div>
          <div v-if="etapa === 19">
            <div class="text-center mt-5 mb-5">
              <h4 class="mb-3">
                Qual o seu e-mail?
              </h4>
            </div>
            <div class="row">
              <div class="col">
                <a-input
                  ref="email"
                  placeholder="Informe o seu e-mail"
                  size="large"
                  type="text"
                  v-model.trim="formAuto.email"
                />
              </div>
            </div>
            <div class="text-center mt-5">
              <Button
                label="CONTINUAR"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.email === ''"
                :classess="
                  formAuto.email === ''
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="next()"
              />
            </div>
            <a-alert
              class="text-center mt-5"
              message="Todas essas informações são necessárias para sua cotação, quanto
              mais informações mais aproximada será sua cotação!"
              type="success"
            />
            <a-alert
              class="text-center mt-5"
              message="Lembrando que todos os seus dados serão estarão seguros conosco!"
              type="success"
            />
          </div>
          <div v-if="etapa === 20">
            <div class="text-center mt-5 mb-5">
              <h4>
                Os seus dados estão corretos?
              </h4>
              <span>Você pode alterar se precisar!</span>
            </div>
            <div class="row">
              <SeguroParaAutomovelDetalhamento :formAuto="this.formAuto" />
            </div>
            <div class="text-center mt-5">
              <h6
                class="mb-4 text-success cursor-pointer"
                @click="irParaEtapa(0)"
              >
                QUERO ALTERAR MEUS DADOS!
              </h6>
              <Button
                label="SOLICITAR COTAÇÃO"
                :loading="false"
                :size="'large'"
                :disabled="formAuto.email === ''"
                :classess="
                  formAuto.email === ''
                    ? 'bg-ligth text-muted'
                    : 'bg-success text-white shadow'
                "
                @emitter-click="solicitarCotacao()"
              />
            </div>
            <p class="text-center mt-5">
              Todas essas informações são necessárias para sua cotação, quanto
              mais informações mais aproximada será sua cotação!
            </p>
            <p class="text-center mt-5">
              Lembrando que todos os seus dados estarão seguros conosco
            </p>
          </div>
          <div v-if="etapa === 21">
            <div class="text-center mt-5 mb-5">
              <a-card hoverable class="w-100 mt-3 bg-success">
                <img slot="cover" alt="example" :src="loadAsset('carro.png')" />
                <a-card-meta>
                  <template slot="description">
                    <h4 class="mb-1 text-white text-uppercase">
                      Wooow!!!
                    </h4>
                    <h6 class="p-0 m-0 text-white text-uppercase">
                      Sua cotação já está sendo preparada!
                    </h6>
                    <h6
                      class="p-0 mt-4 text-white"
                      v-if="this.formAuto.tipoAtendimento === 'W'"
                    >
                      Em alguns instantes nossa equipe entrará em contato com
                      você no seu whatsapp com os detalhes da sua cotação!
                      <div class="mt-3 mb-1">
                        <button type="button" class="btn btn-sm btn-light">
                          <img :src="loadAsset('whatsapp.png')" width="20" />
                          whatsapp {{ this.formAuto.celular }}
                        </button>
                      </div>
                    </h6>
                    <h6
                      class="p-0 mt-4 text-white"
                      v-if="this.formAuto.tipoAtendimento === 'T'"
                    >
                      Em alguns instantes nossa equipe ligará para você com os
                      detalhes da sua cotação!
                      <div class="mt-3 mb-1">
                        <button type="button" class="btn btn-sm btn-light">
                          <a-icon type="phone" /> {{ this.formAuto.celular }}
                        </button>
                      </div>
                    </h6>
                  </template>
                </a-card-meta>
              </a-card>
              <router-link to="/cotacao/categorias">
                <Button
                  label="COTAR NOVAMENTE!"
                  :loading="false"
                  :size="'large'"
                  :disabled="formAuto.email === ''"
                  :classess="'bg-success text-white shadow w-100 mt-5'"
                />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import "@/filters/generic-filters/filters-cpf-testing";
import Button from "@/components/generic-components/button/button";
import SeguroParaAutomovelDetalhamento from "./seguro-para-automovel-detalhamento";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    Button,
    SeguroParaAutomovelDetalhamento,
  },
  beforeCreate() {
    this.$nextTick(() => {
      this.$refs.nomeCliente.$el.focus();
      this.actionListarMarcasCarros();
    });
  },
  computed: {
    ...mapGetters([
      "stateSeguradoras",
      "stateEstadosCivis",
      "stateMarcasCarros",
      "stateIdClienteCotacao",
      "stateMarcasCarrosModelos",
      "stateMarcasCarrosModelosAno",
      "stateMarcasCarrosModelosAnoDetalhe",
    ]),
  },
  data() {
    return {
      etapa: 0,
      selected: 0,
      labelSelected: "",
      percent: 5,
      modalClasseBonus: false,
      exibeMsgCpf: false,
      formAuto: {
        idCategoria: this.$route.params.idCategoria,
        nomeCliente: "",
        tipoAtendimento: "",
        celular: "",
        idMarca: null,
        idModelo: null,
        anoModelo: null,
        bem: {},
        placa: "",
        chassi: "",
        tipoUtilizacao: null,
        possuiSeguro: "",
        idSeguradora: null,
        classeBonus: null,
        houveSinistro: null,
        cobertura1825: null,
        cpf: "",
        cnpj: "",
        nascimento: "",
        cep: "",
        tipoResidencia: null,
        tipoImovel: null,
        valorEstimado: null,
        idEstadoCivil: "",
        anosHabilitacao: null,
        email: "",
      },
    };
  },
  methods: {
    ...mapActions([
      "actionCadastrarCliente",
      "actionSolicitarCotacao",
      "actionListarMarcasCarros",
      "actionListarMarcasCarrosModelos",
      "actionListarMarcasCarrosModelosAno",
      "actionListarMarcasCarrosModelosAnoDetalhe",
    ]),
    selectedCategorie(selected, labelSelected) {
      this.selected = selected;
      this.labelSelected = labelSelected;
      this.formAuto.idCategoria = selected;
    },
    irParaEtapa: function(etapa) {
      this.etapa = etapa;
    },
    naoPossuiSeguro(etapa) {
      this.etapa = etapa;
      this.percent += 15;
      this.habilitarFocoNoInput();
    },
    next: function() {
      this.etapa++;
      let percent = this.percent + 5;
      if (percent > 100) {
        percent = 100;
      }
      this.percent = percent;
      this.habilitarFocoNoInput();
    },
    prev() {
      this.etapa--;
      let percent = this.percent - 5;
      if (percent < 0) {
        percent = 0;
      }
      this.percent = percent;
    },
    habilitarFocoNoInput() {
      if (this.etapa === 1) {
        setTimeout(() => {
          this.$refs.celular.$el.focus();
        }, 10);
      } else if (this.etapa === 6) {
        setTimeout(() => {
          this.$refs.placa.$el.focus();
        }, 10);
      } else if (this.etapa === 7) {
        setTimeout(() => {
          this.$refs.chassi.$el.focus();
        }, 10);
      } else if (this.etapa === 14) {
        setTimeout(() => {
          this.$refs.cpf.$el.focus();
        }, 10);
      } else if (this.etapa === 15) {
        setTimeout(() => {
          this.$refs.nascimento.$el.focus();
        }, 10);
      } else if (this.etapa === 16) {
        setTimeout(() => {
          this.$refs.cep.$el.focus();
        }, 10);
      } else if (this.etapa === 18) {
        setTimeout(() => {
          this.$refs.anosHabilitacao.$el.focus();
        }, 10);
      } else if (this.etapa === 19) {
        setTimeout(() => {
          this.$refs.email.$el.focus();
        }, 10);
      }
    },
    cadastrarCliente() {
      if (this.stateIdClienteCotacao === 0) {
        this.actionCadastrarCliente({
          id_categoria: this.formAuto.idCategoria,
          nome_cliente: this.formAuto.nomeCliente,
          tipo_atendimento: this.formAuto.tipoAtendimento,
          celular: this.formAuto.celular,
        });
      }
      this.next();
    },
    solicitarCotacao() {
      this.actionSolicitarCotacao({
        id_cliente: this.stateIdClienteCotacao,
        id_categoria: this.formAuto.idCategoria,
        nome_cliente: this.formAuto.nomeCliente,
        tipo_atendimento: this.formAuto.tipoAtendimento,
        celular: this.formAuto.celular,
        id_marca: this.formAuto.idMarca,
        id_modelo: this.formAuto.idModelo,
        ano_modelo: this.formAuto.anoModelo,
        bem: this.formAuto.bem,
        placa: this.formAuto.placa,
        chassi: this.formAuto.chassi,
        tipo_utilizacao: this.formAuto.tipoUtilizacao,
        possui_seguro: this.formAuto.possuiSeguro,
        id_seguradora: this.formAuto.idSeguradora,
        classe_bonus: this.formAuto.classeBonus,
        houve_sinistro: this.formAuto.houveSinistro,
        cobertura_1825: this.formAuto.cobertura1825,
        cpf: this.formAuto.cpf,
        cnpj: this.formAuto.cnpj,
        nascimento: this.formAuto.nascimento,
        cep: this.formAuto.cep,
        tipo_residencia: this.formAuto.tipoResidencia,
        tipo_imovel: this.formAuto.tipoImovel,
        valor_estimado: this.formAuto.valorEstimado,
        id_estado_civil: this.formAuto.idEstadoCivil,
        anos_habilitacao: this.formAuto.anosHabilitacao,
        email: this.formAuto.email,
      });
      this.next();
    },
    listarMarcasCarrosModelos(idMarca) {
      this.actionListarMarcasCarrosModelos(idMarca);
    },
    listarMarcasCarrosModelosAno(idModelo) {
      this.actionListarMarcasCarrosModelosAno({
        idMarca: this.formAuto.idMarca,
        idModelo: idModelo,
      });
    },
    async listarMarcasCarrosModelosAnoDetalhe(anoModelo) {
      await this.actionListarMarcasCarrosModelosAnoDetalhe({
        idMarca: this.formAuto.idMarca,
        idModelo: this.formAuto.idModelo,
        anoModelo: anoModelo,
      });
      this.formAuto.bem = this.stateMarcasCarrosModelosAnoDetalhe;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    infoClasseBonus() {
      this.modalClasseBonus = true;
    },
    okClasseBonus() {
      this.modalClasseBonus = false;
    },
    loadAsset(path) {
      return require(`@/assets/img/${path}`);
    },
    limparCampo(campo) {
      this.formAuto[campo] = "";
    },
    testarCpfValido(cpf) {
      if (cpf.target.value.length === 14) {
        if (this.$options.filters.filterCpfTesting(cpf.target.value)) {
          this.exibeMsgCpf = false;
        } else {
          this.exibeMsgCpf = true;
        }
      }
    },
  },
};
</script>
